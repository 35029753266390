<template>
    <div>
        <div v-if="currentPage == 'main'">

            <!-- TABS -->
            <div>
                <div class="row mb-5">
                    <div class="col-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a id="tab-working" class="nav-link" :class="{active: activeTab === 'tab-working'}" href="javascript:void(0);" @click="setTab('tab-working')">Working</a>
                        </li>
                        <li class="nav-item">
                            <a id="tab-most-products" class="nav-link" :class="{active: activeTab === 'tab-most-products'}" href="javascript:void(0);" @click="setTab('tab-most-products')">Most products</a>
                        </li>
                        <li class="nav-item">
                            <a id="tab-least-sellers" class="nav-link" :class="{active: activeTab === 'tab-least-sellers'}" href="javascript:void(0);" @click="setTab('tab-least-sellers')">Least sellers</a>
                        </li>
                        <li class="nav-item">
                            <a id="tab-rejected" class="nav-link" :class="{active: activeTab === 'tab-rejected'}" href="javascript:void(0);" @click="setTab('tab-rejected')">Rejected</a>
                        </li>
                        <li class="nav-item">
                            <a id="tab-added-braid" class="nav-link" :class="{active: activeTab === 'tab-added-braid'}" href="javascript:void(0);" @click="setTab('tab-added-braid')">Successful</a>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
            
            <!-- TABLE -->
            <div v-if="gettingTableData">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning" >
                            Hang on ... getting ebay items
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!gettingTableData">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="float-right">
                            <button type="button" class="btn btn-secondary" @click="nextPage('back')"> Back </button>
                                <span style="margin-left: 10px;">Page {{pageNum}}</span>
                            <button type="button" class="btn btn-secondary" style="margin-left: 10px;" @click="nextPage('next')"> Next </button>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-xs-12 col-md-6">
                        <div class="table table-hover">
                            <table>
                                <thead>
                                    <th>Brand</th>
                                    <th>Listings</th>
                                    <th>Sellers</th>
                                    <th v-if="activeTab == 'tab-working' || activeTab == 'tab-rejected'">Comments</th>
                                </thead>
                                <tbody>
                                    <tr v-if="brands.length === 0">
                                        <td colspan="4"><i>Nothing to see here</i></td>
                                    </tr>
                                    <tr v-for="brand in brands" :key="brand.brand" @click="editBrand(brand)" style="cursor: pointer;">
                                        <!--<td>{{typeof item.itemTitle === 'string' ? item.itemTitle.substring(0, 60) : ''}}</td>-->
                                        <td>{{brand.brand}}</td>
                                        <td>{{brand.itemCount}}</td>
                                        <td>{{brand.sellerCount}}</td>
                                        <td v-if="activeTab == 'tab-working' || activeTab == 'tab-rejected'">{{typeof brand.comments === 'string' ? brand.comments.substring(0, 60) : ''}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div v-if="currentPage == 'editBrand'">

            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div class="float-right">
                        <button type="button" class="btn btn-primary" @click="updateBrand()">Save</button>
                        <button type="button" class="btn btn-secondary ml-3" @click="cancelEdit()">Cancel</button>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-xs-12 col-md-4">
                    <h2>{{currentBrand.brand}}</h2>
                </div>
            </div>

            <div class="row form-group">
                <label class="col-xs-12 col-md-1">State</label>
                <div class="col-xs-12 col-md-7">
                    <select class="form-control" v-model="currentBrand.state">
                        <option value="1">Working</option>
                        <option value="-1">Rejected</option>
                        <option value="9">Added to Braid</option>
                    </select>
                </div>
            </div>

            <div class="row form-group">
                <label class="col-xs-12 col-md-1">Reject reason</label>
                <div class="col-xs-12 col-md-7">
                    <select class="form-control" v-bind:class="{'red-border': rejectReasonEmpty}" v-model="currentBrand.rejectReason" :disabled="currentBrand.state != '-1'">
                        <option value=""></option>
                        <option value="DANGEROUS">Dangerous</option>
                        <option value="TECHNICAL">Electronics/Technical</option>
                        <option value="GENERIC">Generic/unknown</option>
                        <option value="HOLIDAY">Holiday/Festive</option>
                        <option value="IMMORAL">Immoral</option>
                        <option value="MAJOR_BRAND">Major global brand (unable to source)</option>
                        <option value="MEDICAL">Medical</option>
                        <option value="OWN_BRAND">Seller's own brand</option>
                        <option value="PERSONALISED">Personalised</option>
                        <option value="LARGE_HEAVY">Too big/heavy</option>
                        <option value="VAPE">Vape/nicotine</option>
                        <option value="SUPPLIER_REJECTED">Supplier rejected application</option>
                    </select>
                </div>
            </div>

            <div class="row form-group">
                <label class="col-xs-12 col-md-1">Comments</label>
                <div class="col-xs-12 col-md-7">
                    <textarea type="text" class="form-control" v-bind:class="{'red-border': rejectCommentsEmpty}" v-model="currentBrand.comments" rows="8" maxlength="500"></textarea>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-xs-12 col-md-12">
                    <div class="table table-hover">
                        <table>
                            <thead>
                                <th>Item ID</th>
                                <th>Seller</th>
                                <th>Title</th>
                                <th style="text-align: right;">Qty sold</th>
                                <th style="text-align: right;">30 day avg sold</th>
                                <th style="text-align: right;">30 day feedback count</th>
                            </thead>
                            <tbody>
                                <tr v-for="item in currentBrand.items" :key="item.itemId">
                                    <td class="no-overflow"><a href="javascript:void(0);" @click="openEbayElementInNewWindow($event, 'itm', item.itemId)">{{item.itemId}}</a></td>
                                    <td class="no-overflow"><a href="javascript:void(0);" @click="openEbayElementInNewWindow($event, 'usr', item.userId)">{{item.userId}}</a></td>
                                    <td class="no-overflow">{{item.itemTitle}}</td>
                                    <td style="text-align: right;">{{typeof item.quantitySold === 'number' ? item.quantitySold.toLocaleString() : ''}}</td>
                                    <td style="text-align: right;">{{typeof item.avg30daySold === 'number' ? item.avg30daySold.toLocaleString() : ''}}</td>
                                    <td style="text-align: right;">{{typeof item.feedbackCount === 'number' ? item.feedbackCount.toLocaleString() : ''}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
  name: 'EbayItems',
  components: {
    
  },
  data() {
      return {
          activeTab    : 'tab-working',
          currentPage  : 'main',
          pageNum      : 1,
          brands       : [],
          currentBrand : {},
          gettingTableData : true
      }
  },
  computed: {
    //   supplierSourcingOpen() {
    //       return this.supplierSourcing.filter(obj => obj.status == 'New' || obj.status == 'Contacted' || obj.status == 'In progress')
    //   },
    //   supplierSourcingClosed() {
    //       return this.supplierSourcing.filter(obj => obj.status == 'Forwarded price list' || obj.status == 'Rejected')
    //   }
    rejectReasonEmpty() {
        return this.currentBrand.state == '-1' && (typeof this.currentBrand.rejectReason !== 'string' || this.currentBrand.rejectReason.length === 0)
    },
    rejectCommentsEmpty() {
        return this.currentBrand.state == '-1' && (typeof this.currentBrand.comments !== 'string' || this.currentBrand.comments.length < 5)
    }
  },
  mounted() {
    this.getBrands()
  },
  methods: {
      async getBrands() {
          this.gettingTableData = true
          let data = {
              pageNum   : this.pageNum,
              activeTab : this.activeTab
          }
          let response          = await this.apiCall('/ebayProducts/getBrands', data)
          this.brands           = response && response.brands ? response.brands : []
          this.gettingTableData = false
      },
      setTab(tabName) {
          this.activeTab = tabName
          this.pageNum   = 1
          this.getBrands()
      },
      editBrand(brand) {
        this.currentBrand = brand
        this.currentBrand.comments = typeof this.currentBrand.comments === 'string' ? this.currentBrand.comments : ''
        this.currentPage = 'editBrand'
      },
      nextPage(direction) {
          if (direction == 'back' && this.pageNum == 1) {
              return
          }
          if (direction == 'next' && this.pageNum == 10) {
              return
          }
          if (direction == 'back') {
              this.pageNum--
          }
          if (direction == 'next') {
              this.pageNum++
          }
          this.getBrands()
      },
      async updateBrand() {
          if (this.rejectReasonEmpty || this.rejectCommentsEmpty) {
              alert('You must select a reject reason and enter comments before saving')
              return
          }
          let response   = await this.apiCall('/ebayProducts/updateBrand', this.currentBrand)
          if (!response && response.result != 'ok') {
              alert('Error!\n\nSomething went wrong!')
              return
          }
          this.getBrands()
          this.currentPage = 'main'
      },
      cancelEdit() {
          let result = confirm('Are you sure you want to close this item without saving?')
          if (!result) {
              return
          }
          this.currentBrand = {}
          this.currentPage = 'main'
      },
      openEbayElementInNewWindow(e, ebayElement, ebayId) {

        e.preventDefault();
        var url = 'https://www.ebay.co.uk/' + ebayElement + '/' + ebayId
        var site = window.open('');
        site.document.open();
        site.document.writeln('Hang on...')
        let script = site.document.createElement('script')
        script.type='text/javascript';
        script.innerText = 'window.location ="' + url + '"'
        site.document.head.appendChild(script)
        site.document.close()
      }
  }
}
</script>

<style>
table {
  width: 100%;
}
.red-border {
    border: 2px solid #ff0000 !important;
}
.no-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>