<template>
    <div>
        <h3 class="text-center text-white pt-5">Login form</h3>
        <div class="container">
            <div class="row" v-if="displayError">
                <div class="col">
                    <div class="alert alert-danger">
                        Login failed
                    </div>
                </div>
            </div>
            <div id="login-row" class="row justify-content-center align-items-center">
                <div id="login-column" class="col-md-6">
                    <div id="login-box" class="col-md-12">
                        <form id="login-form" class="form" action="" method="post">
                            <h2 class="text-info">Login</h2>
                            <div class="form-group">
                                <label for="username" class="text-info">Email:</label><br>
                                <input type="text" class="form-control" v-model="email">
                            </div>
                            <div class="form-group">
                                <label for="password" class="text-info">Password:</label><br>
                                <input type="password" class="form-control" v-model="password">
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-primary" @click="tryLogin()" :disabled="email.length < 8 || password.length < 8">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    components: {

    },
    data() {
        return {
            email        : '',
            password     : '',
            displayError : false
        }
    },
    methods: {
        async tryLogin() {

            let body = {
                email    : this.email,
                password : this.password
            }

            let response = await this.apiCall('/login', body)

            if (response && response.AUTH === 'VALID') {
                this.$router.push('/dashboard')
                return
            }

            this.displayError = true
        }
    }
}
</script>

<style>
</style>