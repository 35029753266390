<template>
    <div>
        
        <div class="row">
            <div class="col mb-3">
                <h2>Restock</h2>
                <h3>{{supplierName}}</h3>
            </div>
        </div>

        <!-- TABLE OF SUPPLIERS -->
        <div class="row mb-3" v-if="page === 'suppliers'">
            <div class="col">
                <div class="alert alert-info">Only suppliers with a status of "Active" or "Onboarding" will be displayed here</div>
            </div>
        </div>
        <div class="row mt-4 mb-4" v-if="page === 'suppliers'">
            <label class="col-1">Managed by</label>
            <div class="col-2">
                <select class="form-control" v-model="managedByPerson">
                    <option value="all">All</option>
                    <option value="">No manager</option>
                    <option v-for="(person, $index) in managedByPeople" :value="person" :key="$index">{{person}}</option>
                </select>
            </div>
            <div class="col-2">
                <button class="btn btn-secondary" @click="getRestockCounts()" :disabled="gettingRestockCounts || managedByPerson.length <= 3">Get totals</button>
            </div>
            <div class="col-3">
                <span class="alert alert-warning" v-if="gettingRestockCounts">Getting restock counts ...</span>
            </div>
        </div>
        <div class="row" v-if="page === 'suppliers'">
            <div class="col-8">
                <div class="table table-hover">
                    <table class="">
                        <thead>
                            <tr>
                                <th>Supplier</th>
                                <th>Managed by</th>
                                <th>Interval</th>
                                <th>Last PO</th>
                                <th>PO date</th>
                                <th class="text-right">Restock qty</th>
                                <th class="text-right">Not in stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="supplier in filteredSuppliers" :key="supplier.supplierId" @click="displayFilters(supplier)" style="cursor: pointer;">
                                <td>{{supplier.name}}</td>
                                <td>{{supplier.managedBy}}</td>
                                <td>
                                    <select class="form-control" v-model="supplier.restockInterval" @change="updateSupplierRestockInterval($event, supplier.supplierId)" @click="stopProp($event)">
                                        <option value="14">2 weeks</option>
                                        <option value="21">3 weeks</option>
                                        <option value="28">4 weeks</option>
                                        <option value="35">5 weeks</option>
                                        <option value="42">6 weeks</option>
                                        <option value="56">8 weeks</option>
                                    </select>
                                </td>
                                <td>{{supplier.lastPo}}</td>
                                <td>
                                    <span :class="{'danger-badge': supplier.poDaysAgo >= supplier.restockInterval}" v-if="supplier.lastPoCreatedAt.length > 0">{{supplier.lastPoCreatedAt}} ({{supplier.poDaysAgo}} days)</span>
                                    <span class="warning-badge" v-if="supplier.lastPoCreatedAt.length === 0">Never</span>
                                </td>
                                <td class="text-right">{{supplier.totalQtyRestock}}</td>
                                <td class="text-right">{{supplier.totalQtyNewProducts}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- FILTER OPTIONS -->
        <div v-if="page === 'filter'">
            <div class="row mb-5">
                <div class="col">
                    <button type="button" class="btn btn-secondary" @click="displaySuppliers()"><i class="fas fa-arrow-left"></i>&nbsp;&nbsp;&nbsp;Back</button>
                </div>
            </div>
            <!--<div class="row" v-if="!restockType">
                <div class="col">
                    <div class="alert alert-warning">No report type selected</div>
                </div>
            </div>
            <div class="row form-group mb-4">
                <div class="col-4">
                    <input type="radio" id="restock-only" name="restock-type" v-model="restockType" value="restockOnly" style="width: 20px; height: 20px;">
                    <label class="pl-2 pb-2" for="restock-only">Restock only</label>
                    <br>
                    <input type="radio" id="new-products" name="restock-type" v-model="restockType" value="newProducts" style="width: 20px; height: 20px;">
                    <label class="pl-2" for="new-products">SKUs not in stock</label>
                </div>
            </div>-->
            <div class="row form-group">
                <div class="col-4">
                    <label class="">Number of weeks previous to base restock quantities</label>
                    <input type="number" class="form-control" v-model="weeksPrevious" min="1" max="52" step="1">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-4">
                    <label class="">Max sales rank (in thousands)</label>
                    <input type="number" class="form-control" v-model="maxSalesRank" min="1" max="250" step="1" maxlength="3">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-4">
                    <label class="">Minimum profit £</label>
                    <input type="number" class="form-control" v-model="minProfit" min="1" max="250" step="1" maxlength="3">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-4">
                    <label class="">Minimum ROI %</label>
                    <input type="number" class="form-control" v-model="minROI" min="5" step="1" maxlength="3">
                </div>
            </div>
            <div class="row form-group mt-5">
                <div class="col">
                    <button type="button" class="btn btn-primary" @click="displayProducts()" :disabled="!restockType">Get products</button>
                </div>
            </div>
        </div>

        <!-- LIST OF PRODUCTS -->
        <div v-if="page === 'products'">
            
            <div class="row mb-5">
                <div class="col-6">
                    <button type="button" class="btn btn-secondary" @click="poSuccessAlert = false; page = 'filter'"><i class="fas fa-arrow-left"></i>&nbsp;&nbsp;&nbsp;Back</button>
                </div>
                <div class="col-6">
                    <div class="float-right">
                        <button type="button" class="btn btn-primary mr-3" @click="raisePo()" :disabled="raisingPo || Object.keys(poItems).length === 0">Raise PO</button>
                        <button type="button" class="btn btn-secondary" @click="exportToCsv()">Export to CSV</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="restock-subtotal-sticky">
                        <table>
                            <tr>
                                <td>Opex remaining</td>
                                <td class="text-right"> {{totalOpexRemaining.toLocaleString()}}</td>
                            </tr>
                            <tr>
                                <td>Capex spend</td>
                                <td class="text-right">- {{totalCapex.toLocaleString()}}</td>
                            </tr>
                            <tr>
                                <td style="padding-top: 12px !important;">Total value</td>
                                <td style="padding-top: 12px !important;" class="text-right">{{totalValue.toLocaleString()}}</td>
                            </tr>
                            <tr>
                                <td>Unit count</td>
                                <td class="text-right">{{restockUnitCount.toLocaleString()}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="alert alert-success" v-if="poSuccessAlert">PO raised successfully</div>
                    <div class="alert alert-warning" v-if="raisingPo">Please wait - raising purchase order ...</div>
                    <div class="alert alert-info" v-if="gettingProducts">Hang on a mo ...</div>
                    <div class="alert alert-warning" v-if="!gettingProducts && Object.keys(products).length === 0">Uh oh... No products were found.</div>
                    <div class="table table-hover" v-if="Object.keys(products).length > 0">
                        <table>
                            <thead>
                                <th>Title</th>
                                <th>SKU</th>
                                <th>ASIN</th>
                                <th>GTIN</th>
                                <th>MPN</th>
                                <th>Variant 1</th>
                                <th>Variant 2</th>
                                <th>Curr.</th>
                                <th>Cost</th>
                                <th>UK Rank</th>
                                <th>UK Profit</th>
                                <th>UK Sold</th>
                                <th>UK Stock</th>
                                <th>UK Restock</th>
                                <th>UK Suggest</th>
                                <th>DE Rank</th>
                                <th>DE Profit</th>
                                <th>DE Sold</th>
                                <th>DE Stock</th>
                                <th>DE Restock</th>
                                <th>DE Suggest</th>
                                <th>PO qty</th>
                                <!--<th>UK</th>
                                <th>DE</th>
                                <th>ES</th>
                                <th>FR</th>
                                <th>IT</th>
                                <th>PL</th>
                                <th class="total-bg-purple">Total qty</th>
                                <th>Total cost</th>-->
                            </thead>
                            <tbody>
                                <tr v-for="product in products" :key="product.skuId">
                                    <td>{{product.title}}</td>
                                    <td>{{product.sku}}</td>
                                    <td>{{product.asin}}</td>
                                    <td>{{product.gtin}}</td>
                                    <td>{{product.mpn}}</td>
                                    <td>{{product.variant1Value}}</td>
                                    <td>{{product.variant2Value}}</td>
                                    <td>{{product.currency}}</td>
                                    <td class="text-right">{{product.supplierPriceCost}}</td>
                                    <td class="text-right bg-purple-4">{{product.ch1.salesRank}}</td>
                                    <td class="text-right bg-purple-3">{{product.ch1.potentialProfit}}</td>
                                    <td class="text-right bg-purple-2">{{product.ch1.soldQuantity}}</td>
                                    <td class="text-right bg-purple-1">
                                        <span v-if="!isNaN(product.ch1.inStock) && product.ch1.inStock > 0">F {{product.ch1.inStock}}</span>
                                        <span v-if="!isNaN(product.ch1.inStock) && product.ch1.inStock > 0 && !isNaN(product.yorkQuantity) && product.yorkQuantity > 0"><br></span>
                                        <span v-if="!isNaN(product.yorkQuantity) && product.yorkQuantity > 0">M {{product.yorkQuantity}}</span>
                                    </td>
                                    <td class="text-right bg-green-2">{{product.ch1.qtyRestock == 0 ? '' : product.ch1.qtyRestock}}</td>
                                    <td class="text-right bg-green-1">{{product.ch1.qtySuggestNewProduct == 0 ? '' : product.ch1.qtySuggestNewProduct}}</td>
                                    <td class="text-right bg-purple-4">{{product.ch2.salesRank}}</td>
                                    <td class="text-right bg-purple-3">{{product.ch2.potentialProfit}}</td>
                                    <td class="text-right bg-purple-2">{{product.ch2.soldQuantity}}</td>
                                    <td class="text-right bg-purple-1">{{product.ch2.inStock == 0 ? '' : product.ch2.inStock}}</td>
                                    <td class="text-right bg-green-2">{{product.ch2.qtyRestock == 0 ? '' : product.ch2.qtyRestock}}</td>
                                    <td class="text-right bg-green-1">{{product.ch2.qtySuggestNewProduct == 0 ? '' : product.ch2.qtySuggestNewProduct}}</td>
                                    <td class="text-right"><input type="number" maxlength="2" class="form-control text-right" @input="addPoItem($event, product)" onkeydown="return event.keyCode !== 69"></td>
                                    <!--<td class="text-right bg-purple-1">{{product.ch1.buyQty}}</td>
                                    <td class="text-right bg-purple-2">{{product.ch2.buyQty}}</td>
                                    <td class="text-right bg-purple-3">{{product.ch3.buyQty}}</td>
                                    <td class="text-right bg-purple-4">{{product.ch4.buyQty}}</td>
                                    <td class="text-right bg-purple-5">{{product.ch5.buyQty}}</td>
                                    <td class="text-right bg-purple-6">{{product.ch10.buyQty}}</td>
                                    <td class="text-right">{{product.totalQty}}</td>
                                    <td class="text-right">{{product.totalCost}}</td>-->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Restock',
        components: {

        },
        data() {
            return {
                restockType     : 'newProducts',
                defaultRestockInterval : 21, // days
                page            : 'suppliers',
                products        : [],
                suppliers       : [],
                managedByPeople : [],
                managedByPerson : 'all',
                braidTenantSupplierId : null,
                supplierId      : null,
                supplierName    : '',
                currency        : '',
                weeksPrevious   : '4',
                maxSalesRank    : '100',
                minProfit       : '0.8',
                minROI          : '12',
                gettingProducts : false,
                poItems         : {},
                poSuccessAlert  : false,
                raisingPo       : false,
                gettingRestockCounts : false,
                totalOpexBudget    : 0,
                totalOpexRemaining : 0,
                totalCapex         : 0,
                totalValue         : 0,
                restockUnitCount   : 0
            }
        },
        mounted() {
            this.getActiveSuppliers()
        },
        computed: {
            filteredSuppliers() {
                let suppliers = []
                for (var index in this.suppliers) {
                    let supplier = this.suppliers[index]
                    console.log(supplier.restockInterval)
                    supplier.restockInterval = supplier.restockInterval == '' || supplier.restockInterval == null ? this.defaultRestockInterval : supplier.restockInterval
                    if (this.managedByPerson === 'all' || supplier.managedBy == this.managedByPerson) {
                        suppliers.push(supplier)
                    }
                }
                return suppliers
            }
        },
        methods: {
            async displayFilters(supplier) {
                this.supplierId      = supplier.supplierId
                this.braidTenantSupplierId = supplier.braidTenantSupplierId
                this.supplierName    = supplier.name
                this.currency        = supplier.currency
                this.page            = 'filter'
            },
            displaySuppliers() {
                this.supplierId   = null
                this.supplierName = ''
                this.page         = 'suppliers'
            },
            async getRestockCounts() {
                this.gettingRestockCounts = true
                let body     = { managedBy : this.managedByPerson }
                let response = await this.apiCall('/restock/getRestockCounts', body)
                if (typeof response.restockCounts !== 'object' || response.restockCounts.length === 0) {
                    return
                }
                for (let index in this.suppliers) {
                    let supplier   = this.suppliers[index]
                    let supplierId = supplier.supplierId
                    if (typeof response.restockCounts[supplierId] !== 'object') {
                        continue
                    }
                    let counts = response.restockCounts[supplierId]
                    let totalQtyRestock = counts.totalQtyRestock > 0 ? counts.totalQtyRestock : ''
                    let totalQtyNewProducts = counts.totalQtyNewProducts > 0 ? counts.totalQtyNewProducts : ''
                    this.$set(this.suppliers[index], 'totalQtyRestock', totalQtyRestock)
                    this.$set(this.suppliers[index], 'totalQtyNewProducts', totalQtyNewProducts)
                }
                this.gettingRestockCounts = false
            },
            async displayProducts() {
                this.products   = []
                this.poItems    = {}
                this.totalOpexBudget    = 0
                this.totalOpexRemaining = 0
                this.totalCapex = 0
                this.totalValue = 0
                this.restockUnitCount = 0
                this.getProducts()
                this.page = 'products'
            },
            async getActiveSuppliers() {
                let response = await this.apiCall('/suppliers/getActiveSuppliers')
                if (response) {
                    this.suppliers       = response.suppliers ? response.suppliers : []
                    this.managedByPeople = response.managedByPeople ? response.managedByPeople : []
                }
            },
            stopProp(event) {
                event.stopPropagation()
            },
            async updateSupplierRestockInterval(event, supplierId) {
                event.stopPropagation()
                let params = {
                    restockInterval : event.target.value
                }
                let response = await this.apiCall('/restock/updateSupplierRestockInterval/' + supplierId, params)
                if (response === true) {
                    alert('SUCCESS: Updated supplier restock interval')
                } else {
                    alert('ERROR: Failed to update')
                }
            },
            async getProducts() {
                let params = {
                    restockType   : this.restockType,
                    supplierId    : this.supplierId,
                    weeksPrevious : this.weeksPrevious,
                    maxSalesRank  : this.maxSalesRank,
                    minProfit     : this.minProfit,
                    minROI        : this.minROI
                }
                this.gettingProducts = true
                let response  = await this.apiCall('/restock/getProductsBySupplier/' + this.supplierId, params)
                this.gettingProducts = false
                if (response) {
                    this.products = response.products ? response.products : []
                    this.totalOpexBudget = response.opexBudget ? response.opexBudget : 0
                }
                this.processProductQuantities()
                this.updateTotalsBox()
            },
            processProductQuantities() {
                let channels = ['1', '2', '3', '4', '5', '8', '9', '10']
                for (let key in this.products) {
                    let product       = this.products[key]
                    product.totalQty  = 0
                    product.totalCost = 0
                    for (let index in channels) {
                        let key = 'ch' + channels[index];
                        // Add a channel object, if it doesn't exist
                        if (!product[key]) {
                            product[key] = {}
                        }
                        // Remove zero and negatives
                        if (isNaN(product[key].buyQty) || product[key].buyQty <= 0) {
                            product[key].buyQty = ''
                        }
                        // Make all values two decimal places
                        if (!isNaN(product[key].buyQty) && product[key].buyQty > 0) {
                            product.totalQty  += product[key].buyQty
                        }
                    }
                    if (!isNaN(product.supplierPriceCost)) {
                        product.totalCost = product.totalQty * parseFloat(product.supplierPriceCost)
                        product.totalCost = product.totalCost > 0 ? product.totalCost.toFixed(2) : ''
                    }
                    product.totalQty = product.totalQty > 0 ? product.totalQty : ''
                    product.supplierPriceCost === 'number' ? product.supplierPriceCost.toFixed(2) : product.supplierPriceCost
                }
            },
            addPoItem(event, product) {
                console.log(product)
                let quantity  = event.target.value == '' ? 0 : event.target.value
                if (quantity == 0) {
                    delete this.poItems[product.sku]
                    this.updateTotalsBox()
                    return
                }
                let soldUk = typeof product.ch1.soldQuantity === 'undefined' ? 0 : product.ch1.soldQuantity
                let soldDe = typeof product.ch2.soldQuantity === 'undefined' ? 0 : product.ch2.soldQuantity
                let obj = {
                    braidTenantSkuId : product.braidTenantSkuId,
                    priceCost        : product.supplierPriceCost,
                    quantity         : quantity,
                    soldUk           : soldUk,
                    soldDe           : soldDe,
                    soldTotal        : soldUk + soldDe
                }
                this.$set(this.poItems, product.sku, obj)
                this.updateTotalsBox()
            },
            updateTotalsBox() {
                // calculate subtotal to display within sticky
                let totalOpexRemaining = this.totalOpexBudget
                let totalCapex = 0
                let totalValue = 0
                let unitCount  = 0
                console.log(this.poItems)
                for (let key in this.poItems) {
                    let item      = this.poItems[key]
                    let quantity  = parseInt(item.quantity)
                    let priceCost = parseFloat(item.priceCost)
                    let lineTotal = quantity * priceCost
                    totalValue    += lineTotal
                    unitCount     += quantity
                    totalOpexRemaining -= lineTotal
                }
                totalValue         = Math.round(totalValue)
                totalOpexRemaining = Math.round(totalOpexRemaining)
                if (totalOpexRemaining < 0) {
                    totalCapex         = Math.round(Math.abs(totalOpexRemaining))
                    totalOpexRemaining = 0
                }
                this.$set(this, 'totalOpexRemaining', totalOpexRemaining)
                this.$set(this, 'totalCapex', totalCapex)
                this.$set(this, 'totalValue', totalValue)
                this.$set(this, 'restockUnitCount', unitCount)
            },
            async raisePo() {
                console.log(this.poItems)
                this.raisingPo = true
                let data = {
                    braidTenantSupplierId : this.braidTenantSupplierId,
                    supplierId : this.supplierId,
                    poItems    : this.poItems,
                    totalCapex : this.totalCapex
                }
                let response = await this.apiCall('/purchaseOrders/raisePo', data)
                if (response.result === 'ok') {
                    this.poItems = {}
                    this.poSuccessAlert = true
                }
                this.raisingPo = false
            },
            exportToCsv() {
                console.log(this.products)
                let headers = [
                    'Supplier',
                    'Brand',
                    'Title',
                    'Variant 1',
                    'Variant 2',
                    'SKU',
                    'ASIN',
                    'GTIN',
                    'MPN',
                    'Curr',
                    'Cost',
                    'UK',
                    'DE',
                    'ES',
                    'FR',
                    'IT',
                    'NL',
                    'PL',
                    'SE',
                    'Total qty',
                ]

                let countries = [
                    { channel: 'ch1',  name: 'UK' },
                    { channel: 'ch2',  name: 'DE' },
                    { channel: 'ch3',  name: 'ES' },
                    { channel: 'ch4',  name: 'FR' },
                    { channel: 'ch5',  name: 'IT' },
                    { channel: 'ch8',  name: 'NL' },
                    { channel: 'ch10', name: 'PL' },
                    { channel: 'ch9',  name: 'SE' }
                ]

                let countryCols = [
                    { field: 'inStock',         label: 'in stock'     },
                    { field: 'soldQuantity',    label: 'sold'         },
                    { field: 'qtyRestock',      label: 'restock'      },
                    { field: 'qtySuggestNewProduct', label: 'suggest' },
                    { field: 'salesRank',       label: 'salesRank'    },
                    { field: 'category',        label: 'category'     },
                    { field: 'landedPrice',     label: 'landed price' },
                    { field: 'potentialProfit', label: 'profit'       },
                    { field: 'potentialMargin', label: 'margin'       }
                ]

                for (var index in countries) {
                    
                    let country = countries[index]
                    
                    for (var i2 in countryCols) {
                        let countryCol = countryCols[i2]
                        headers.push(country.name + ' ' + countryCol.label)
                    }
                }

                let rows = [headers]

                for (let sku in this.products) {

                    let item         = this.products[sku]
                    let totalRestock = isNaN(item.totalRestock) ? 0 : item.totalRestock
                    totalRestock     = totalRestock == 0 ? '' : totalRestock

                    for (let index in countries) {
                        
                        let channel = countries[index].channel
                        this['displayQty' + channel] = ''

                        if (typeof item[channel] !== 'object') {
                            continue
                        }

                        let displayQty = 0
                        displayQty     += isNaN(item[channel].qtyRestock) ? 0 : item[channel].qtyRestock
                        displayQty     += isNaN(item[channel].qtySuggestNewProduct) ? 0 : item[channel].qtySuggestNewProduct
                        this['displayQty' + channel] = displayQty == 0 ? '' : displayQty
                    }

                    let row = [
                        this.supplierName.split(',').join(' '),
                        typeof item.brandName === 'string' ? item.brandName.split(',').join(' ') : '',
                        typeof item.title === 'string' ? item.title.split(',').join(' ') : '',
                        typeof item.variant1Value === 'string' ? item.variant1Value.split(',').join(' ') : '',
                        typeof item.variant2Value === 'string' ? item.variant2Value.split(',').join(' ') : '',
                        typeof item.sku === 'string' ? item.sku.split(',').join(' ') : '',
                        typeof item.asin === 'string' ? item.asin.split(',').join(' ') : '',
                        typeof item.gtin === 'string' ? item.gtin.split(',').join(' ') : '',
                        typeof item.mpn === 'string' ? item.mpn.split(',').join(' ') : '',
                        typeof item.currency === 'string' ? item.currency.split(',').join(' ') : '',
                        typeof item.supplierPriceCost !== 'undefined' ? item.supplierPriceCost : '',
                        this.displayQtych1,
                        this.displayQtych2,
                        this.displayQtych3,
                        this.displayQtych4,
                        this.displayQtych5,
                        this.displayQtych8,
                        this.displayQtych9,
                        this.displayQtych10,
                        // typeof item.ch1 !== 'object' || isNaN(item.ch1.buyQty) ? '' : item.ch1.buyQty,
                        // typeof item.ch2 !== 'object' || isNaN(item.ch2.buyQty) ? '' : item.ch2.buyQty,
                        // typeof item.ch3 !== 'object' || isNaN(item.ch3.buyQty) ? '' : item.ch3.buyQty,
                        // typeof item.ch4 !== 'object' || isNaN(item.ch4.buyQty) ? '' : item.ch4.buyQty,
                        // typeof item.ch5 !== 'object' || isNaN(item.ch5.buyQty) ? '' : item.ch5.buyQty,
                        // typeof item.ch8 !== 'object' || isNaN(item.ch8.buyQty) ? '' : item.ch8.buyQty,
                        // typeof item.ch10 !== 'object' || isNaN(item.ch10.buyQty) ? '' : item.ch10.buyQty,
                        // typeof item.ch9 !== 'object' || isNaN(item.ch9.buyQty) ? '' : item.ch9.buyQty,
                        totalRestock,
                    ]

                    for (let i1 in countries) {
                    
                        let country = countries[i1]
                        let channel = country.channel
                    
                        for (let i2 in countryCols) {
                            
                            if (typeof item[channel] === 'undefined') {
                                row.push('')
                                continue
                            }

                            let countryCol = countryCols[i2]
                            let field = countryCol.field
                            
                            if (typeof item[channel][field] === 'undefined' || item[channel][field] == 0) {
                                row.push('')
                                continue
                            }

                            row.push(item[channel][field])
                        }
                    }

                    rows.push(row)
                }

                this.createCsvExport('Restock - ' + this.supplierName, rows)
            }
        }
    }
    </script>

<style>
.restock-subtotal-sticky {
  background-color: #3949ab;
  color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 132px;
  padding: 5px 20px;
  font-size: 1.1em;
  z-index: 1;
}
.bg-green-1 {
  background-color: #e0f2f1;
}
tr:hover .bg-green-1 {
  background-color: #d4edec;
}
.bg-green-2 {
  background-color: #d7eeee;
}
tr:hover .bg-green-2 {
  background-color: #c9e8e8;
}
.bg-purple-1 {
    background-color: #f4f5fb;
}
tr:hover .bg-purple-1 {
    background-color: #eceef8;
}
.bg-purple-2 {
    background-color: #e8eaf6;
}
tr:hover .bg-purple-2 {
    background-color: #dadef1;
}
.bg-purple-3 {
    background-color: #dadef1;
}
tr:hover .bg-purple-3 {
    background-color: #c8cdea;
}
.bg-purple-4 {
    background-color: #c8cdea;
}
tr:hover .bg-purple-4 {
    background-color: #b5bce3;
}
.bg-purple-5 {
    background-color: #b6bce2;
}
tr:hover .bg-purple-5 {
    background-color: #a4abdb;
}
/*.bg-purple-6 {
    background-color: #a3acdb;
}*/
.warning-badge {
    padding: 4px 9px;
    background-color: #ffd54f;
    border-radius: 5px;
}
.danger-badge {
    padding: 4px 9px;
    background-color: #ad1457;
    border-radius: 5px;
    color: #ffffff;
}
</style>