import Vue from 'vue'
import App from './App.vue'
import router from './router'
import apiCall from './components/mixins/apiCall'
import csvExport from './components/mixins/csvExport'

Vue.use(apiCall)
Vue.mixin(csvExport)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')