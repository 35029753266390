<template>
  <div id="app">
    
    <Login v-if="$route.name === 'Login'" />
    
    <div v-if="$route.name !== 'Login'">
      <TopNav />
      <div id="content-body" class="container-fluid">
        <router-view></router-view>
      </div>
    </div>

  </div>
</template>

<script>
import Login from './components/Login.vue'
import TopNav from './components/TopNav.vue'

export default {
  name: 'App',
  components: {
    Login,
    TopNav
  },
  data() {
    return {
      page : this.$route.name
    }
  },
  watch: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#content-body {
  padding: 30px 50px;
}
</style>