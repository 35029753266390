<template>
    <div>

      <!-- TABS -->
      <div>
        <div class="row mb-5">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a id="tab-supplier" class="nav-link" :class="{active: activeTab === 'tab-shipped'}" href="javascript:void(0);" @click="setTab('tab-shipped')">Shipped</a>
                </li>
                <li class="nav-item">
                  <a id="tab-weekly" class="nav-link" :class="{active: activeTab === 'tab-plan'}" href="javascript:void(0);" @click="setTab('tab-plan')">Plan</a>
                </li>
                <li class="nav-item">
                  <a id="tab-weekly" class="nav-link" :class="{active: activeTab === 'tab-working'}" href="javascript:void(0);" @click="setTab('tab-working')">Working</a>
                </li>
              </ul>
            </div>
        </div>
      </div>

      <div v-if="activeTab === 'tab-shipped'">
        <div class="row" v-if="gettingShipmentData">
          <div class="col-12">
            <div class="alert alert-warning">Getting shipment info ...</div>
          </div>
        </div>

        <div v-if="!gettingShipmentData">
          <div class="row">
            <div class="col-12">
              <h2>UK inbound</h2>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="table table-hover">
                <table>
                  <thead>
                    <th>Shipment</th>
                    <th>Status</th>
                    <th>SKU</th>
                    <th>Supplier</th>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Sent</th>
                    <th>Received</th>
                  </thead>
                  <tbody>
                    <tr v-if="fbaInboundUK.length === 0">
                      <td colspan="7">No inbound shipments</td>
                    </tr>
                    <tr v-for="item in fbaInboundUK" :key="item.amazonShipmentItemId">
                      <td>{{item.shipmentId}}</td>
                      <td><span class="pill" :class="[{'pill-orange': item.shipmentStatus == 'Receiving'}, {'pill-red': item.shipmentStatus == 'Closed'}]">{{item.shipmentStatus}}</span></td>
                      <td>{{item.sku}}</td>
                      <td>{{item.supplierName}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.destinationFulfillmentCenterId}}, {{item.locality}}</td>
                      <td>{{item.quantityShipped}}</td>
                      <td>{{item.quantityReceived}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <h2>DE inbound</h2>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="table table-hover">
                <table>
                  <thead>
                    <th>Shipment</th>
                    <th>Status</th>
                    <th>SKU</th>
                    <th>Supplier</th>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Sent</th>
                    <th>Received</th>
                  </thead>
                  <tbody>
                    <tr v-if="fbaInboundDE.length === 0">
                      <td colspan="7">No inbound shipments</td>
                    </tr>
                    <tr v-for="item in fbaInboundDE" :key="item.amazonShipmentItemId">
                      <td>{{item.shipmentId}}</td>
                      <td><span class="pill" :class="[{'pill-orange': item.shipmentStatus == 'Receiving'}, {'pill-red': item.shipmentStatus == 'Closed'}]">{{item.shipmentStatus}}</span></td>
                      <td>{{item.sku}}</td>
                      <td>{{item.supplierName}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.destinationFulfillmentCenterId}}, {{item.locality}}</td>
                      <td>{{item.quantityShipped}}</td>
                      <td>{{item.quantityReceived}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <h2>Discrepencies</h2>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="table table-hover">
                <table>
                  <thead>
                    <th>Shipment</th>
                    <th>Status</th>
                    <th>SKU</th>
                    <th>Supplier</th>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Sent</th>
                    <th>Received</th>
                  </thead>
                  <tbody>
                    <tr v-for="item in fbaInboundDiscrepencies" :key="item.amazonShipmentItemId">
                      <td>{{item.shipmentId}}</td>
                      <td><span class="pill" :class="[{'pill-orange': item.shipmentStatus == 'Receiving'}, {'pill-red': item.shipmentStatus == 'Closed'}]">{{item.shipmentStatus}}</span></td>
                      <td>{{item.sku}}</td>
                      <td>{{item.supplierName}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.destinationFulfillmentCenterId}}, {{item.locality}}</td>
                      <td>{{item.quantityShipped}}</td>
                      <td>{{item.quantityReceived}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PLAN TAB -->
      <div v-if="activeTab === 'tab-plan'">
        
        <div class="row" v-if="gettingPlanningData">
          <div class="col-12">
            <div class="alert alert-warning">Getting inventory data ...</div>
          </div>
        </div>

        <div class="row mb-5" v-if="!gettingPlanningData">
          <div class="col">
            <div class="float-right">
              <button class="btn btn-primary" @click="generateAmazonTemplate()">Generate Amazon template</button>
              <button class="btn btn-danger ml-2" @click="transferStock()">Transfer out stock</button>
              <button class="btn btn-secondary ml-2" @click="clearQuantities()">Clear quantities</button>
            </div>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col">
              <div class="alert alert-success" v-if="stockTransferSuccess === true"><strong>SUCCESS</strong>: Deducted inventory quantities from Braid York FBA location</div>
              <div class="alert alert-danger" v-if="stockTransferSuccess === false"><strong>ERROR</strong>:  Failed to deduct inventory quantities.  <strong>DO NOT RETRY</strong>.  Inform Andy immediately.</div>
          </div>
        </div>

        <div class="row" v-if="!gettingPlanningData">
          <div class="col">
            <div class="table table-hover">
              <table>
                <thead>
                  <th>Title</th>
                  <th>SKU</th>
                  <th>ASIN</th>
                  <th>Variant 1</th>
                  <th>Variant 2</th>
                  <th>In stock</th>
                  <th style="width: 30px;"></th>
                  <th>UK Rank</th>
                  <th>UK Profit</th>
                  <th>UK Sold</th>
                  <th>UK Stock</th>
                  <th>UK Restock</th>
                  <th>UK Suggest</th>
                  <th>UK Send</th>
                  <th style="width: 30px;"></th>
                  <th>DE Rank</th>
                  <th>DE Profit</th>
                  <th>DE Sold</th>
                  <th>DE Stock</th>
                  <th>DE Restock</th>
                  <th>DE Suggest</th>
                  <th>DE Send</th>
                </thead>
                <tbody>
                  <tr v-if="inventory.length === 0">
                    <td colspan="19">No items found in local inventory</td>
                  </tr>
                  <tr v-for="item in inventory" :key="item.sku">
                    <td>{{item.brandName}} {{item.title}}</td>
                    <td>{{item.sku}}</td>
                    <td>{{item.asin}}</td>
                    <td>{{item.variant1Value}}</td>
                    <td>{{item.variant2Value}}</td>
                    <td class="text-right bg-yellow" title="York FBA stock">{{item.yorkQuantity}}</td>
                    <td></td>
                    <td class="text-right bg-purple-4">{{item.ch1.salesRank === null || isNaN(item.ch1.salesRank) ? '' : item.ch1.salesRank.toLocaleString()}}</td>
                    <td class="text-right bg-purple-3" title="UK estimated profit">{{item.ch1.potentialProfit}}</td>
                    <td class="text-right bg-purple-2" title="UK sold quantity">{{item.ch1.soldQuantity}}</td>
                    <td class="text-right bg-purple-1" title="UK stock in FBA warehouses">{{item.ch1.inStock == 0 ? '' : item.ch1.inStock}}</td>
                    <td class="text-right bg-green-2" title="UK restock quantity">{{item.ch1.qtyRestock == 0 ? '' : item.ch1.qtyRestock}}</td>
                    <td class="text-right bg-green-1" title="UK suggested quantity">{{item.ch1.qtySuggestNewProduct == 0 ? '' : item.ch1.qtySuggestNewProduct}}</td>
                    <td class="text-right"><input type="number" maxlength="2" class="form-control text-right" @input="updateSendQuantity($event, item, 'UK')" onkeydown="return event.keyCode !== 69" v-model="sendQuantitiesUK[item.sku]"></td>
                    <td></td>
                    <td class="text-right bg-purple-4">{{item.ch2.salesRank === null || isNaN(item.ch2.salesRank) ? '' : item.ch2.salesRank.toLocaleString()}}</td>
                    <td class="text-right bg-purple-3" title="DE estimated profit">{{item.ch2.potentialProfit}}</td>
                    <td class="text-right bg-purple-2" title="DE sold quantity">{{item.ch2.soldQuantity}}</td>
                    <td class="text-right bg-purple-1" title="DE stock in FBA warehouses">{{item.ch2.inStock == 0 ? '' : item.ch2.inStock}}</td>
                    <td class="text-right bg-green-2" title="DE restock quantity">{{item.ch2.qtyRestock == 0 ? '' : item.ch2.qtyRestock}}</td>
                    <td class="text-right bg-green-1" title="DE suggested quantity">{{item.ch2.qtySuggestNewProduct == 0 ? '' : item.ch2.qtySuggestNewProduct}}</td>
                    <td class="text-right"><input type="number" maxlength="2" class="form-control text-right" @input="updateSendQuantity($event, item, 'DE')" onkeydown="return event.keyCode !== 69" v-model="sendQuantitiesDE[item.sku]"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      
      <!-- WORKING TAB -->
      <div v-if="activeTab === 'tab-working'">

        <div class="row" v-if="gettingShipmentData">
          <div class="col-12">
            <div class="alert alert-warning">Getting shipment info ...</div>
          </div>
        </div>

        <div class="row mb-4" v-if="!gettingShipmentData">
          <div class="col-12">
            <div class="float-right">
              <button class="btn btn-secondary" @click="listInboundShipments()">Refresh Amazon data</button>
            </div>
          </div>
        </div>

        <div class="row" v-if="!gettingShipmentData">
          <div class="col-12">
            <div class="table table-hover">
              <table>
                <thead>
                  <tr>
                    <th>Shipment ID</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Destination</th>
                    <th>Created</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="fbaWorkingShipment.length === 0">
                    <td colspan="4">There are no shipments in "Working" status.</td>
                  </tr>
                  <tr v-for="shipment in fbaWorkingAndShipped" :key="shipment.shipmentId">
                    <td>{{shipment.shipmentId}}</td>
                    <td><span class="pill" :class="[{'pill-green': shipment.shipmentStatus == 'Working'}, {'pill-orange': shipment.shipmentStatus == 'Shipped'}, {'pill-red': shipment.shipmentStatus == 'Closed'}]">{{shipment.shipmentStatus}}</span></td>
                    <td>{{shipment.shipmentName}}</td>
                    <td>{{shipment.destinationFulfillmentCenterId}}, {{shipment.locality}}</td>
                    <td>{{shipment.createdDate}}</td>
                    <td><a href="javascript:void(0)" @click="generateShipmentCsv(shipment, 'frontSheet')">Front sheet</a></td>
                    <td><a href="javascript:void(0)" @click="generateShipmentCsv(shipment, 'customs')">Customs</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>
</template>

<script>
export default {
  name: 'Shipments',
  components: {
    
  },
  data() {
      return {
          fbaInbound           : [],
          inventory            : [],
          gettingShipmentData  : false,
          gettingPlanningData  : false,
          activeTab            : 'tab-shipped',
          sendQuantitiesUK     : {},
          sendQuantitiesDE     : {},
          stockTransferSuccess : null
      }
  },
  mounted() {
    this.getFbaShipments()
  },
  computed: {
    fbaInboundUK() {
      let result = []
      for (var index in this.fbaInbound) {
        let item = this.fbaInbound[index]
        if (item.quantityReceived > 0 || item.country != 'UK') continue
        result.push(item)
      }
      return result
    },
    fbaInboundDE() {
      let result = []
      for (var index in this.fbaInbound) {
        let item = this.fbaInbound[index]
        if (item.quantityReceived > 0 || (item.country != 'DE' && item.country != 'PL')) continue
        result.push(item)
      }
      return result
    },
    fbaInboundDiscrepencies() {
      let result = []
      for (var index in this.fbaInbound) {
        let item = this.fbaInbound[index]
        if (item.quantityReceived == 0) continue
        result.push(item)
      }
      return result
    },
    fbaWorkingAndShipped() {
      let result = []
      for (var index in this.fbaWorkingShipment) {
        var shipment = this.fbaWorkingShipment[index]
        if (shipment.shipmentStatus !== 'Working' && shipment.shipmentStatus !== 'Shipped') {
          continue
        }
        result.push(shipment)
      }
      return result
    }
  },
  methods: {
      async getFbaShipments() {
          this.gettingShipmentData = true
          let response = await this.apiCall('/shipments/getFbaShipments')
          this.fbaInbound = response && response.fbaInbound ? response.fbaInbound : {}
          this.fbaWorkingShipment = response && response.fbaWorkingShipment ? response.fbaWorkingShipment : []
          this.fbaWorkingItem = response && response.fbaWorkingItem ? response.fbaWorkingItem : []
          this.gettingShipmentData = false
      },
      setTab(tabId) {
        this.activeTab = tabId
        if (tabId == 'tab-plan') {
          this.getPlanningData()
        }
      },
      async getPlanningData() {
          this.gettingPlanningData = true
          let response = await this.apiCall('/inventory/getPlanningData')
          this.inventory = response && response.inventory ? response.inventory : []
          this.gettingPlanningData = false
          this.sendQuantitiesUK = {}
          this.sendQuantitiesDE = {}
          // RETRIEVE QUANTITIES FROM LOCALSTORAGE
          for (var index in this.inventory) {
            let item = this.inventory[index]
            let qtyUK = localStorage.getItem(item.sku + 'UK')
            let qtyDE = localStorage.getItem(item.sku + 'DE')
            if (qtyUK !== null) {
              console.log(item.sku + ' : ' + qtyUK)
              this.$set(this.sendQuantitiesUK, item.sku, qtyUK)
            }
            if (qtyDE !== null) {
              console.log(item.sku + ' : ' + qtyDE)
              this.$set(this.sendQuantitiesDE, item.sku, qtyDE)
            }
          }
      },
      async listInboundShipments() {
        // Call inventory taskWorker to grab latest FBA inbound shipments and update local DB
        this.gettingShipmentData = true
        let url = 'https://inventory.lucidtwo.com/taskWorker/listInboundShipments/2/1'
        let params = {
          headers : {
              // 'Accept': 'application/json',
              // 'Content-Type': 'application/json'
          },
          //credentials: 'include',
          body : '', //formData, //JSON.stringify(body),
          method : 'POST',
          mode : 'no-cors'
        }
        let vm = this
        let response = await fetch(url, params)
        .then(data => { 
            vm.getFbaShipments()
            return data.json() 
        })
        .catch(err => console.log(err))
        console.log(response)
      },
      updateSendQuantity(event, item, countryCode) {
        let value = isNaN(event.target.value) ? false : event.target.value
        if (value === false) {
          localStorage.removeItem(item.sku + countryCode)
          if (typeof this['sendQuantities' + countryCode][item.sku] !== 'undefined') {
            delete this['sendQuantities' + countryCode][item.sku]
          }
          return
        }
        this['sendQuantities' + countryCode][item.sku] = value
        localStorage.setItem(item.sku + countryCode, value)
      },
      generateAmazonTemplate() {
        this.generatePlanCSV('UK')
        this.generatePlanCSV('DE')
      },
      generatePlanCSV(countryCode) {
        let rows = []
        rows.push(['PlanName', countryCode])
        rows.push(['ShipToCountry', countryCode])
        rows.push(['AddressName', 'Claire Smith'])
        rows.push(['AddressFieldOne', 'Unit 2 DSP House'])
        rows.push(['AddressFieldTwo', '2 Kettlestring Lane'])
        rows.push(['AddressCity', 'York'])
        rows.push(['AddressCountryCode', 'UK'])
        rows.push(['AddressStateOrRegion', 'North Yorkshire'])
        rows.push(['AddressPostalCode', 'YO30 4XF'])
        rows.push(['AddressDistrict', ''])
        rows.push([''])
        rows.push(['MerchantSKU', 'Quantity'])
        
        let quantities = this['sendQuantities' + countryCode]
        let count = 0

        for (let sku in quantities) {
          let quantity = quantities[sku]
          rows.push([sku, quantity])
          count++
        }
        if (count === 0) {
          console.log('No quantities for country ' + countryCode)
          return
        }
        console.log(rows)
        this.createCsvExport('FBA template ' + countryCode, rows, '	', 'txt')
      },
      async transferStock() {
        let result = confirm('STOP!\n\nThis action will deduct all stock quantities below from the MFN inventory location in Braid. THIS CANNOT BE UNDONE.\n\nAre you sure?')
        if (!result) {
          return
        }
        this.gettingPlanningData = true
        let body = {
          sendQuantitiesUK : this.sendQuantitiesUK,
          sendQuantitiesDE : this.sendQuantitiesDE
        }
        let response = await this.apiCall('/inventory/transferOutStock', body)
        result = response && response.result ? response.result : false
        if (result === true) {
          this.clearQuantities(true)
          this.stockTransferSuccess = true
          this.gettingPlanningData = false
          this.getPlanningData()
        } else {
          this.stockTransferSuccess = false
          this.gettingPlanningData = true // Don't display the SKU list, so the danger alert is easier to see
          alert('ERROR\n\nFailed to update Braid inventory quantities.\n\nDO NOT RETRY !\n\nInform Andy immediately.')
        }
      },
      clearQuantities(skipConfirm) {
        if (!skipConfirm) {
          let result = confirm('Are you sure?')
          if (!result) {
            return
          }
        }
        localStorage.clear()
        this.sendQuantitiesUK = {}
        this.sendQuantitiesDE = {}
      },
      generateShipmentCsv(shipment, csvType) {
        let csvName = ''
        let rows = []
        rows.push(['Shipment ID', shipment.shipmentId])
        rows.push(['Shipment name', shipment.shipmentName])
        rows.push(['Destination', shipment.destinationFulfillmentCenterId + ' - ' + shipment.locality])
        rows.push([])
        
        if (csvType == 'customs') {
          rows = this.getCsvDataCustoms(shipment, rows)
          csvName = 'Customs'
        } else {
          rows = this.getCsvDataFrontSheet(shipment, rows)
          csvName = 'Front sheet'
        }

        this.createCsvExport(csvName + ' - ' + shipment.shipmentId, rows)
      },
      getCsvDataFrontSheet(shipment, rows) {
        rows.push([
          'Brand',
          'Title',
          'Variant',
          'Variant',
          'GTIN',
          'ASIN',
          'FNSKU',
          'Quantity'
        ])

        for (let index in this.fbaWorkingItem) {
          
          let item = this.fbaWorkingItem[index]
          
          if (item.shipmentId != shipment.shipmentId) {
            continue
          }

          rows.push([
            item.sku,
            item.brandName,
            item.title,
            item.variant1Value,
            item.variant2Value,
            item.gtin,
            item.asin,
            item.fnsku,
            item.quantityShipped
          ])
        }

        return rows
      },
      getCsvDataCustoms(shipment, rows) {
        rows.push([
          'Title',
          'Quantity',
          'HS Code',
          'Origin',
          'Line total'
        ])

        let customsRows    = {}
        let totalQuantity  = 0
        let totalPriceCost = 0
        let allItems       = []

        for (let index in this.fbaWorkingItem) {
          
          let item = this.fbaWorkingItem[index]
          
          if (item.shipmentId != shipment.shipmentId) {
            continue
          }

          let title           = item.title.replaceAll(',', '')
          let hsCode          = item.hsCode
          let countryOfOrigin = item.countryOfOrigin
          let key             = hsCode + countryOfOrigin
          let quantityShipped = parseFloat(item.quantityShipped)
          let lineTotal       = item.priceCost * quantityShipped
          
          totalQuantity       += quantityShipped
          totalPriceCost      += lineTotal

          if (typeof customsRows[key] === 'undefined') {
            customsRows[key] = {
              title           : item.brandName + ' ' + title,
              hsCode          : hsCode,
              countryOfOrigin : countryOfOrigin,
              quantityShipped : 0,
              lineTotal       : 0
            }
          }

          customsRows[key].quantityShipped += quantityShipped
          customsRows[key].lineTotal += lineTotal
console.log('quantityShipped=' + quantityShipped + ', typeof=' + typeof quantityShipped)
          // Write every item to another array, so that we can print per-SKU data onto CSV for working out which SKUs are missing required attributes such as country of origin
          allItems.push([
            item.brandName,
            title,
            quantityShipped,
            item.hsCode,
            item.countryOfOrigin,
            item.priceCost,
            lineTotal
          ])
        }

        for (let key in customsRows) {
          
          let row = customsRows[key]

          rows.push([
            row.title,
            row.quantityShipped,
            row.hsCode,
            row.countryOfOrigin,
            row.lineTotal
          ])
        }

        rows.push([
          '',
          totalQuantity,
          '',
          '',
          totalPriceCost
        ])

        rows.push([])
        rows.push(['The following rows are a breakdown of all items:'])
        rows.push([])
        rows.push([
          'Brand',
          'Title',
          'Quantity',
          'HS Code',
          'Origin',
          'Cost',
          'Line total'
        ])

        rows = rows.concat(allItems)

        return rows
      },
  }
}
</script>

<style>
table {
  width: 100%;
}
.bg-yellow {
  background-color: #fff9c4;
}
.pill {
  padding: 4px 8px;
  border-radius: 3px;
}
.pill-green {
  background-color: #00bfa5;
  color: #ffffff;
}
.pill-orange {
  background-color: #f57c00;
  color: #ffffff;
}
.pill-red {
  background-color: #ad1457;
  color: #ffffff;
}
</style>