<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-brand href="#" to="/"><a href="/" class="a-logo">Risana</a></b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item href="#" to="/dashboard" v-if="!sourcingOnly">Dashboard</b-nav-item>
                <b-nav-item href="#" to="/shipments" v-if="!sourcingOnly">Shipments</b-nav-item>
                <b-nav-item href="#" to="/sales" v-if="isAnAdmin">Sales</b-nav-item>
                <b-nav-item href="#" to="/restock" v-if="!sourcingOnly">Restock</b-nav-item>
                <b-nav-item href="#" to="/budget" v-if="!sourcingOnly">Budget</b-nav-item>
                <b-nav-item href="#" to="/supplierProducts" v-if="!sourcingOnly">Supplier prod</b-nav-item>
                <b-nav-item href="#" to="/ebayItems">Ebay items</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item right href="#">Log out</b-nav-item>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
  name: 'TopNav',
  components: {
    
  },
  data() {
    return {
        isAnAdmin : this.isAdmin,
        sourcingOnly : this.sourcingOnly
    }
  },
  mounted() {

  },
}
</script>

<style>
.a-logo,
.a-logo:hover {
  text-decoration: none;
  color: #fff;
}
</style>