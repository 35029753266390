export default {
    install(Vue) {
        Vue.prototype.apiCall = async function(path, body) {
            
            const options = this.$options

            if (options.auth) {
                this.$auth = options.auth;
                console.log('****options.auth')
            } else if (options.parent && options.parent.$auth) {
                this.$auth = options.parent.$auth;
                console.log('****options.parent.auth')
            } else {
                this.$auth = 'INVALID'
                console.log('****NEW AUTH')
            }
            
            let baseUrl = 'https://api.risana.co.uk/api'
            
            if (window.location.hostname == 'dev.risana.co.uk' || window.location.hostname == 'dev.risana.co.uk:8081') {
                baseUrl = 'http://dev.risana.co.uk:8001/api'
            }

            if (this.$auth === 'VALID' || path === '/login') {
                
                let url      = baseUrl + path
                let formData = new FormData();

                if (typeof body === 'object') {
                    for (let key in body) {
                        formData.append(key, body[key]);
                    }
                }

                let params = {
                    headers : {
                        // 'Accept': 'application/json',
                        // 'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body : JSON.stringify(body), //formData, //JSON.stringify(body),
                    method : 'POST'
                }

                let response = await fetch(url, params)
                    .then(data => { 
                        return data.json() 
                    })
                    .catch(err => console.log(err))
                
                console.log(response)
                
                let auth = response.AUTH ? response.AUTH : this.$auth
                Vue.prototype.$auth = auth
                Vue.prototype.username = response.username
                Vue.prototype.isAdmin = response.isAdmin
                Vue.prototype.sourcingOnly = response.sourcingOnly
                
                console.log('_____ SETTING $AUTH _____')
                console.log(auth)

                return response
            }
            
            if (this.$route.name !== 'Login') {
                this.$router.push('/');
            }
        }
    }
}