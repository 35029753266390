<template>
    <div>
        
        <div class="row mb-3">
          <div class="col-xs-12 col-md-3">
              <h2>Sales reports</h2>
          </div>
          <div class="col-xs-6 col-md-1">
            <input type="number" class="form-control" v-model="daysPrevious">
          </div>
          <label class="col-xs-6 col-md-2">days previous (180 max)</label>
          <div class="col-xs-12 col-md-1">
            <button type="button" class="btn btn-primary" @click="getSummary()">Update</button>
          </div>
        </div>


        <!-- TABS -->
        <div>
          <div class="row mb-5">
              <div class="col-12">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a id="tab-supplier" class="nav-link" :class="{active: activeTab === 'tab-supplier'}" href="javascript:void(0);" @click="setTab('tab-supplier')">Supplier</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-weekly" class="nav-link" :class="{active: activeTab === 'tab-weekly'}" href="javascript:void(0);" @click="setTab('tab-weekly')">Weekly</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-daily" class="nav-link" :class="{active: activeTab === 'tab-daily'}" href="javascript:void(0);" @click="setTab('tab-daily')">Daily</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-all-items" class="nav-link" :class="{active: activeTab === 'tab-all-items'}" href="javascript:void(0);" @click="setTab('tab-all-items')">Items</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-all-refunds" class="nav-link" :class="{active: activeTab === 'tab-all-refunds'}" href="javascript:void(0);" @click="setTab('tab-all-refunds')">Refunds</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-poor-profit" class="nav-link" :class="{active: activeTab === 'tab-poor-profit'}" href="javascript:void(0);" @click="setTab('tab-poor-profit')">Poor</a>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        
        <div v-if="gettingData">
          <div class="row mt-5 mb-5">
            <div class="col-12">
              <div class="alert alert-warning">Woah - hold on a sec ... calculating data</div>
            </div>
          </div>
        </div>
        

        <div v-if="activeTab === 'tab-supplier' && !gettingData">
          <div class="row">
            <div class="col">
            <div class="table table-hover">
              <table>
                  <thead>
                      <tr>
                          <th>ID</th>
                          <th>Supplier</th>
                          <th class="text-align-right">Ordered</th>
                          <th class="text-align-right">Dispatched</th>
                          <th class="text-align-right">Revenue</th>
                          <th class="text-align-right">Cost</th>
                          <th class="text-align-right">Profit</th>
                          <th class="text-align-right">ROI %</th>
                          <th class="text-align-right">Refund</th>
                          <th class="text-align-right">UK</th>
                          <th class="text-align-right">DE</th>
                          <th class="text-align-right">ES</th>
                          <th class="text-align-right">FR</th>
                          <th class="text-align-right">IT</th>
                          <th class="text-align-right">NL</th>
                          <th class="text-align-right">PL</th>
                          <th class="text-align-right">SE</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="row in salesData.supplierSales" :key="row.supplierId">
                          <td>{{row.supplierId}}</td>
                          <td>{{row.supplierName}}</td>
                          <td class="text-align-right">{{row.ordered.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.dispatched.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.revenue.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.cost.toLocaleString()}}</td>
                          <td class="text-align-right bg-green">{{row.profit.toLocaleString()}}</td>
                          <td class="text-align-right bg-green-light">{{row.roi}}</td>
                          <td class="text-align-right bg-red-light">{{row.refund}}</td>
                          <td class="text-align-right">{{row.ch1.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch2.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch3.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch4.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch5.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch8.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch10.toLocaleString()}}</td>
                          <td class="text-align-right">{{row.ch9.toLocaleString()}}</td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>

        <div v-if="activeTab === 'tab-weekly' && !gettingData">
          <div class="table table-hover">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th class="text-align-right">Ordered</th>
                        <th class="text-align-right">Dispatched</th>
                        <th class="text-align-right">Revenue</th>
                        <th class="text-align-right">Cost</th>
                        <th class="text-align-right">Profit</th>
                        <th class="text-align-right">ROI %</th>
                        <th class="text-align-right">Refund</th>
                        <th class="text-align-right">UK</th>
                        <th class="text-align-right">DE</th>
                        <th class="text-align-right">ES</th>
                        <th class="text-align-right">FR</th>
                        <th class="text-align-right">IT</th>
                        <th class="text-align-right">NL</th>
                        <th class="text-align-right">PL</th>
                        <th class="text-align-right">SE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in salesData.weeklySales" :key="row.date">
                        <td>{{row.date}}</td>
                        <td class="text-align-right">{{row.ordered.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.dispatched.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.revenue.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.cost.toLocaleString()}}</td>
                        <td class="text-align-right bg-green">{{row.profit.toLocaleString()}}</td>
                        <td class="text-align-right bg-green-light">{{row.roi}}</td>
                        <td class="text-align-right bg-red-light">{{row.refund}}</td>
                        <td class="text-align-right">{{row.ch1.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch2.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch3.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch4.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch5.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch8.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch10.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch9.toLocaleString()}}</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

        <div v-if="activeTab === 'tab-daily' && !gettingData">
          <div class="table table-hover">
            <table>
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Date</th>
                        <th class="text-align-right">Ordered</th>
                        <th class="text-align-right">Dispatched</th>
                        <th class="text-align-right">Revenue</th>
                        <th class="text-align-right">Cost</th>
                        <th class="text-align-right">Profit</th>
                        <th class="text-align-right">ROI %</th>
                        <th class="text-align-right">Refund</th>
                        <th class="text-align-right">UK</th>
                        <th class="text-align-right">DE</th>
                        <th class="text-align-right">ES</th>
                        <th class="text-align-right">FR</th>
                        <th class="text-align-right">IT</th>
                        <th class="text-align-right">NL</th>
                        <th class="text-align-right">PL</th>
                        <th class="text-align-right">SE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in salesData.dailySales" :key="row.date">
                        <td>{{row.day}}</td>
                        <td>{{row.date}}</td>
                        <td class="text-align-right">{{row.ordered.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.dispatched.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.revenue.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.cost.toLocaleString()}}</td>
                        <td class="text-align-right bg-green">{{row.profit.toLocaleString()}}</td>
                        <td class="text-align-right bg-green-light">{{row.roi}}</td>
                        <td class="text-align-right bg-red-light">{{row.refund}}</td>
                        <td class="text-align-right">{{row.ch1.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch2.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch3.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch4.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch5.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch8.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch10.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.ch9.toLocaleString()}}</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

        <div v-if="activeTab === 'tab-all-items' && !gettingData">
          <div class="table table-hover">
            <table>
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th class="text-align-right">Ordered</th>
                        <th class="text-align-right">Dispatched</th>
                        <th class="text-align-right">Revenue</th>
                        <th class="text-align-right">Cost</th>
                        <th class="text-align-right">Profit</th>
                        <th class="text-align-right">ROI %</th>
                        <th>Site</th>
                        <th>Deliver</th>
                        <th>Supplier</th>
                        <th>Title</th>
                        <th>SKU</th>
                        <th>ASIN</th>
                        <th>Order #</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in salesData.allItems" :key="row.orderItemsId">
                        <td class="">{{row.day}}</td>
                        <td class="">{{row.date}}</td>
                        <td class="" :class="{ 'bg-red-light': row.ordered == 0}">{{row.orderStatus}}</td>
                        <td class="text-align-right" :class="[{'bg-red-light': row.ordered == 0}, {'bg-yellow-light': row.ordered != row.dispatched}]">{{row.ordered.toLocaleString()}}</td>
                        <td class="text-align-right" :class="[{'bg-red-light': row.ordered == 0}, {'bg-yellow-light': row.ordered != row.dispatched}]">{{row.dispatched.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.revenue.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.cost.toLocaleString()}}</td>
                        <td class="text-align-right bg-green">{{row.profit.toLocaleString()}}</td>
                        <td class="text-align-right bg-green-light">{{row.roi}}</td>
                        <td class="">{{row.marketplace}}</td>
                        <td class="">{{row.deliveryCountry}}</td>
                        <td>{{row.supplierName}}</td>
                        <td>{{row.title}}</td>
                        <td class="">{{row.sku}}</td>
                        <td class=""><a :href="getAmazonUrl(row.marketplace, row.asin)" target="_blank">{{row.asin}}</a></td>
                        <td class=""><a :href="'https://sellercentral.amazon.co.uk/orders-v3/order/' + row.channelOrderId" target="_blank">{{row.channelOrderId}}</a></td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

        <div v-if="activeTab === 'tab-all-refunds' && !gettingData">
          <div class="table table-hover">
            <table>
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Date</th>
                        <th class="text-align-right">Ordered</th>
                        <th class="text-align-right">Dispatched</th>
                        <th class="text-align-right">Revenue</th>
                        <th class="text-align-right">Cost</th>
                        <th class="text-align-right">Profit</th>
                        <th class="text-align-right">ROI %</th>
                        <th class="text-align-right">Refund</th>
                        <th>Site</th>
                        <th>Deliver</th>
                        <th>Supplier</th>
                        <th>Title</th>
                        <th>SKU</th>
                        <th>ASIN</th>
                        <th>Order #</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in salesData.allRefunds" :key="row.orderItemsId">
                        <td class="">{{row.day}}</td>
                        <td class="">{{row.date}}</td>
                        <td class="text-align-right" :class="[{'bg-red-light': row.ordered == 0}, {'bg-yellow-light': row.ordered != row.dispatched}]">{{row.ordered.toLocaleString()}}</td>
                        <td class="text-align-right" :class="[{'bg-red-light': row.ordered == 0}, {'bg-yellow-light': row.ordered != row.dispatched}]">{{row.dispatched.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.revenue.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.cost.toLocaleString()}}</td>
                        <td class="text-align-right bg-green">{{row.profit.toLocaleString()}}</td>
                        <td class="text-align-right bg-green-light">{{row.roi}}</td>
                        <td class="text-align-right bg-red-light">{{row.refund}}</td>
                        <td class="">{{row.marketplace}}</td>
                        <td class="">{{row.deliveryCountry}}</td>
                        <td>{{row.supplierName}}</td>
                        <td>{{row.title}}</td>
                        <td class="">{{row.sku}}</td>
                        <td class=""><a :href="getAmazonUrl(row.marketplace, row.asin)" target="_blank">{{row.asin}}</a></td>
                        <td class=""><a :href="'https://sellercentral.amazon.co.uk/orders-v3/order/' + row.channelOrderId" target="_blank">{{row.channelOrderId}}</a></td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

        <div v-if="activeTab === 'tab-poor-profit' && !gettingData">
          <div class="table table-hover">
            <table>
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Date</th>
                        <th class="text-align-right">Ordered</th>
                        <th class="text-align-right">Dispatched</th>
                        <th class="text-align-right">Revenue</th>
                        <th class="text-align-right">Cost</th>
                        <th class="text-align-right">Profit</th>
                        <th class="text-align-right">Refund</th>
                        <th class="text-align-right">FBA fee</th>
                        <th>Site</th>
                        <th>Deliver</th>
                        <th>Supplier</th>
                        <th>Title</th>
                        <th>SKU</th>
                        <th>ASIN</th>
                        <th>Order #</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in salesData.poorProfit" :key="row.orderItemsId">
                        <td class="">{{row.day}}</td>
                        <td class="">{{row.date}}</td>
                        <td class="text-align-right" :class="[{'bg-red-light': row.ordered == 0}, {'bg-yellow-light': row.ordered != row.dispatched}]">{{row.ordered.toLocaleString()}}</td>
                        <td class="text-align-right" :class="[{'bg-red-light': row.ordered == 0}, {'bg-yellow-light': row.ordered != row.dispatched}]">{{row.dispatched.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.revenue.toLocaleString()}}</td>
                        <td class="text-align-right">{{row.cost.toLocaleString()}}</td>
                        <td class="text-align-right bg-green">{{row.profit.toLocaleString()}}</td>
                        <td class="text-align-right bg-red-light">{{row.refund}}</td>
                        <td class="text-align-right">{{row.fbaFee}}</td>
                        <td class="">{{row.marketplace}}</td>
                        <td class="">{{row.deliveryCountry}}</td>
                        <td>{{row.supplierName}}</td>
                        <td>{{row.title}}</td>
                        <td class="">{{row.sku}}</td>
                        <td class=""><a :href="getAmazonUrl(row.marketplace, row.asin)" target="_blank">{{row.asin}}</a></td>
                        <td class=""><a :href="'https://sellercentral.amazon.co.uk/orders-v3/order/' + row.channelOrderId" target="_blank">{{row.channelOrderId}}</a></td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Sales',
    components: {

    },
    data() {
        return {
            activeTab    : 'tab-supplier',
            daysPrevious : 31,
            salesData    : {},
            gettingData  : true
        }
    },
    mounted() {
        this.getSummary()
    },
    methods: {
        async getSummary() {
            this.gettingData = true
            let response   = await this.apiCall('/sales/getSummary?daysPrevious=' + this.daysPrevious)
            this.salesData = response && response.salesData ? response.salesData : {}
            this.gettingData = false
        },
        setTab(tabId) {
          this.activeTab = tabId
        },
        getAmazonUrl(marketplace, asin) {
          let baseUrl = 'https://www.amazon.'
          let tld = ''
          switch (marketplace) {
            case 'UK': tld = 'co.uk'; break;
            case 'DE': tld = 'de';    break;
            case 'ES': tld = 'es';    break;
            case 'FR': tld = 'fr';    break;
            case 'IT': tld = 'it';    break;
            case 'NL': tld = 'nl';    break;
            case 'PL': tld = 'pl';    break;
            case 'SE': tld = 'se';    break;
          }
          return baseUrl + tld + '/dp/' + asin
        }
    }
}
</script>

<style>

 .text-align-right {
   text-align: right;
 }
 .bg-green {
   background-color: #e0f2f1;
 }
.bg-green-light {
  background-color: #f1f9f9;
}
.bg-red-light {
  background-color: #fcedf2;
}
.bg-yellow-light {
  background-color: #fffde7;
}
</style>