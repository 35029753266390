<template>
    <div>
        
        <div class="row mb-3">
            <div class="col">
                <h2>Budget</h2>
            </div>
        </div>

        <!-- TABS -->
        <div>
          <div class="row mb-5">
              <div class="col-12">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a id="tab-budget" class="nav-link" :class="{active: activeTab === 'tab-budget'}" href="javascript:void(0);" @click="setTab('tab-budget')">Budgets</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-capex-spend" class="nav-link" :class="{active: activeTab === 'tab-capex-spend'}" href="javascript:void(0);" @click="setTab('tab-capex-spend')">Capex spend</a>
                  </li>
                  <li class="nav-item">
                    <a id="tab-capex-budget" class="nav-link" :class="{active: activeTab === 'tab-capex-budget'}" href="javascript:void(0);" @click="setTab('tab-capex-budget')">Capex budget</a>
                  </li>
                </ul>
              </div>
          </div>
        </div>

        <!-- TABLE OF SUPPLIERS -->
        <div class="row" v-if="activeTab == 'tab-budget'">
            <div class="col-xs-12 col-md-8">
                <div class="table table-hover">
                    <table>
                        <thead>
                            <tr>
                                <th>Supplier</th>
                                <th>Currency</th>
                                <th class="text-align-right">Order revenue</th>
                                <th class="text-align-right">PO spend</th>
                                <th class="text-align-right">Capex spent</th>
                                <th class="text-align-right">Opex remaining</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="supplier in suppliers" :key="supplier._id">
                                <td>{{supplier.name}}</td>
                                <td>{{supplier.currency}}</td>
                                <td class="text-align-right">{{!supplier.totalRevenue ? '' : supplier.totalRevenue.toLocaleString()}}</td>
                                <td class="text-align-right">{{!supplier.totalPoValue ? '' : supplier.totalPoValue.toLocaleString()}}</td>
                                <td class="text-align-right">{{supplier.totalCapexSpend}}</td>
                                <td class="text-align-right" :class="[{'bg-green': supplier.totalOpexRemaining > 0}, {'bg-red-light': supplier.totalOpexRemaining < 0}]">{{!supplier.totalOpexRemaining ? '' : supplier.totalOpexRemaining.toLocaleString()}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- CAPEX SPEND -->
        <div class="row" v-if="activeTab == 'tab-capex-spend'">
            <div class="col-xs-12 col-md-8">
                <div class="table table-hover">
                    <table>
                        <thead>
                            <tr>
                                <th>Supplier</th>
                                <th>Currency</th>
                                <th>PO number</th>
                                <th class="text-align-right">Value</th>
                                <th>User</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in capexSpend" :key="row.capexSpendId">
                                <td>{{row.name}}</td>
                                <td>{{row.currency}}</td>
                                <td>{{row.poNumber}}</td>
                                <td class="text-align-right">{{!row.capexValue ? '' : Math.round(row.capexValue.toLocaleString())}}</td>
                                <td>{{row.createdBy}}</td>
                                <td>{{row.createdDate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- CAPEX BUDGET -->
        <div class="row mb-5" v-if="activeTab == 'tab-capex-budget'">
            <label class="col-xs-12 col-md-2">Capex available £</label>
            <div class="col-xs-12 col-md-1" :disabled="username != 'andy@risana.co.uk' && username != 'claire@risana.co.uk'">
                <input type="text" class="form-control" v-model="capexAvailable">
            </div>
            <div class="col-xs-12 col-md-2" v-if="username == 'andy@risana.co.uk' || username == 'claire@risana.co.uk'">
                <button class="btn btn-primary" @click="updateCapexValue()">Update</button>
            </div>
        </div>

        <div class="row" v-if="activeTab == 'tab-capex-budget'">
            <div class="col-xs-12 col-md-8">
                <div class="table table-hover">
                    <table>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th class="text-align-right">New value</th>
                                <th>User</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in capexBudget" :key="row.capexBudgetId">
                                <td>{{row.action}}</td>
                                <td class="text-align-right">{{!row.currentValue ? '' : Math.round(row.currentValue.toLocaleString())}}</td>
                                <td>{{row.createdBy}}</td>
                                <td>{{row.createdDate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Budget',
    components: {

    },
    data() {
        return {
            activeTab      : 'tab-budget',
            suppliers      : [],
            capexAvailable : 0,
            capexSpend     : [],
            capexBudget    : []
        }
    },
    mounted() {
        console.log('auth')
        console.log(this.username)
        this.getSupplierBudgets()
    },
    methods: {
        setTab(tabName) {
            this.activeTab = tabName
            if (tabName == 'tab-capex-spend' || tabName == 'tab-capex-budget') {
                this.getCapexData()
            }
        },
        async getSupplierBudgets() {
            let response   = await this.apiCall('/budget/getAllSupplierBudgets')
            this.suppliers = response && response.suppliers ? response.suppliers : []
        },
        async getCapexData() {
            let response        = await this.apiCall('/budget/getCapexData')
            this.capexAvailable = 0
            this.currentBudget  = []
            this.currentSpend   = []
            if (response) {
                this.capexAvailable = response.capexAvailable ? response.capexAvailable : 0
                this.capexBudget    = response.capexBudget ? response.capexBudget : 0
                this.capexSpend     = response.capexSpend ? response.capexSpend : []
            }
        },
        async updateCapexValue() {
            let params = {
                currentValue : this.capexAvailable
            }
            let response        = await this.apiCall('/budget/updateCapexValue', params)
            this.capexAvailable = 0
            this.currentBudget  = []
            this.currentSpend   = []
            if (response) {
                this.capexAvailable = response.capexAvailable ? response.capexAvailable : 0
                this.capexBudget    = response.capexBudget ? response.capexBudget : 0
                this.capexSpend     = response.capexSpend ? response.capexSpend : []
            }
        }
    }
}
</script>

<style>

    .bg-green {
        background-color: #c9e8e8;
    }
    .bg-red-light {
        background-color: #fcedf2;
    }
    .text-align-right {
        text-align: right;
    }

</style>