<template>
    <div>
      <div class="row">
        
        <!--<div class="col-xs-12 col-md-6">
          <div class="table">
            <table>
              <thead>
                <th>Currency</th>
                <th>Exchange</th>
                <th>Last updated</th>
              </thead>
              <tr v-for="product in dashboardData.missingDimensions" :key="product.productId">
                <td>{{product.title}}</td>
              </tr>
            </table>
          </div>
        </div>-->

        <div class="col-xs-12 col-md-6">
          <div class="table">
            <table>
              <thead>
                <th>Currency</th>
                <th>Exchange</th>
                <th>Last updated</th>
              </thead>
              <tr v-for="currency in dashboardData.currencies" :key="currency.currencyId">
                <td>{{currency.currencyCode}}</td>
                <td>{{currency.exchangeRate}}</td>
                <td>{{currency.lastUpdated}}</td>
              </tr>
            </table>
          </div>
        </div>

      </div>

      <div class="row" v-if="!sourcingOnly">
        
        <div class="col-xs-12 col-md-6">
            <div class="table table-hover">
              <table>
                <thead>
                  <th>SKU</th>
                  <th>Issue</th>
                </thead>
                <tr v-for="issue in dashboardData.issueSku" :key="issue.issueSkuId">
                  <td>{{issue.sku}}</td>
                  <td>
                    <div v-if="issue.missingPriceCost">Missing cost price</div>
                    <div v-if="issue.missingDimensions">Missing dimensions</div>
                    <div v-if="issue.missingCountryOfOrigin">Missing origin</div>
                    <div v-if="issue.missingHsCode">Missing HS code</div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="col-xs-12 col-md-6" v-if="!sourcingOnly">
            <div class="table table-hover">
              <table>
                <thead>
                  <th>Supplier</th>
                  <th>Issue</th>
                  <th>Managed by</th>
                </thead>
                <tr v-for="issue in dashboardData.issueSupplier" :key="issue.issueSupplierId">
                  <td>{{issue.name}}</td>
                  <td>
                    <div v-if="issue.missingCurrency">Missing currency</div>
                    <div v-if="issue.missingWarehouseCountry">Missing warehouse country</div>
                  </td>
                  <td>{{issue.managedBy}}</td>
                </tr>
              </table>
            </div>
          </div>

      </div>

    </div>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
    
  },
  data() {
      return {
          dashboardData : {},
          sourcingOnly : this.sourcingOnly
      }
  },
  mounted() {
    this.getDashboardData()
  },
  methods: {
      async getDashboardData() {
          let response   = await this.apiCall('/dashboard/getSummary')
          this.dashboardData = response && response.dashboardData ? response.dashboardData : {}
      }
  }
}
</script>

<style>
table {
  width: 100%;
}
</style>