export default {
    methods: {
        createCsvExport(fileName, rows, delimeter, fileExt) {

            if (typeof delimeter === 'undefined') {
                delimeter = ','
            }

            if (typeof fileExt === 'undefined') {
                fileExt = 'csv'
            }

            var csvContent = 'data:text/csv;charset=utf-8,'
            fileName       = fileName + '.' + fileExt

            rows.forEach(function(rowArray) {

                var row    = rowArray.join(delimeter);
                csvContent += row + '\r\n';
            });

            // if (typeof MSBlobBuilder !== 'undefined') {
            //     // for MS Edge
            //     var bb = new MSBlobBuilder();
            //     bb.append(csvContent);
            //     var blob = bb.getBlob('text/csv;charset=utf-8');
            //     navigator.msSaveBlob(blob, csvFileName);
            //     return;
            // }

            var encodedUri = encodeURI(csvContent);
            var link       = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', fileName);
            document.body.appendChild(link); // Required for FF

            link.click();
        }
    }
}