import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '../components/Dashboard'
import Login from '../components/Login'
import Sales from '../components/Sales'
import Shipments from '../components/Shipments'
import Restock from '../components/Restock'
import Budget from '../components/Budget'
import SupplierProducts from '../components/SupplierProducts'
import EbayItems from '../components/EbayItems'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import jQuery from "jquery";

// var $ = jQuery;
// window.$ = $;

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/sales',
      name: 'Sales',
      component: Sales
    },
    {
      path: '/shipments',
      name: 'Shipments',
      component: Shipments
    },
    {
      path: '/restock',
      name: 'Restock',
      component: Restock
    },
    {
      path: '/budget',
      name: 'Budget',
      component: Budget
    },
    {
      path: '/supplierProducts',
      name: 'SupplierProducts',
      component: SupplierProducts
    },
    {
      path: '/ebayItems',
      name: 'EbayItems',
      component: EbayItems
    }
  ]
})
