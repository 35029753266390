<template>
    <div>
        
        <div class="row mb-3" v-if="!supplier">
            <div class="col">
                <h2>Suppliers</h2>
            </div>
        </div>

        <!-- TABLE OF SUPPLIERS -->
        <div class="row" v-if="!supplier">
            <div class="col">
                <div class="table table-hover">
                    <table>
                        <tbody>
                            <tr v-for="supplier in suppliers" :key="supplier._id" @click="setSupplier(supplier)" style="cursor: pointer;">
                                <td>{{supplier.name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- LIST OF SUPPLIER'S PRODUCTS -->
        <div v-if="supplier">
            
            <div class="row">
                <div class="col">
                    <button type="button" class="btn btn-primary" @click="clearSupplier()"><i class="fa fa-arrow-back"></i>&nbsp;&nbsp;&nbsp;Go back</button>
                </div>
            </div>

            <div class="row mt-4 mb-4">
                <div class="col">
                    <h3>{{supplier.name}}</h3>
                </div>
            </div>

            <div class="row mb-4" v-if="!gotProducts">
                <div class="col">
                    <div class="alert alert-warning">Chill out.  Getting supplier products ...</div>
                </div>
            </div>

            <div class="row mb-4" v-if="gotProducts">
                <div class="col">
                    <div class="alert alert-info">This page only displays products which are not in the local inventory database.  i.e. everything below is a product we have never bought or sold.</div>
                </div>
            </div>

            <div class="row" v-if="gotProducts">
                <div class="col">
                    <div class="table table-hover">
                        <table>
                            <thead>
                                <tr>
                                    <th>Supplier SKU</th>
                                    <th>Name</th>
                                    <th>ASIN</th>
                                    <th>GTIN</th>
                                    <th>Cost</th>
                                    <th>Currency</th>
                                    <th>UK</th>
                                    <th>DE</th>
                                    <th>ES</th>
                                    <th>FR</th>
                                    <th>IT</th>
                                    <th>NL</th>
                                    <th>PL</th>
                                    <th>SE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in products" :key="product._id">
                                    <td>{{product.supplierSku}}</td>
                                    <td>{{product.name}}</td>
                                    <td>{{product.asin}}</td>
                                    <td>{{product.gtin}}</td>
                                    <td>{{product.priceCost}}</td>
                                    <td>{{product.currency}}</td>
                                    <td class="country-column-1"><span v-if="product.marketplaces.uk.goodProduct">Y</span></td>
                                    <td class="country-column-1"><span v-if="product.marketplaces.uk.category1SalesRank">{{product.marketplaces.uk.category1SalesRank}}</span></td>
                                    <td class="country-column-2"><span v-if="product.marketplaces.de.goodProduct">Y</span></td>
                                    <!--<td class="country-column-3"><span v-if="product.marketplaces.es.goodProduct">Y</span></td>
                                    <td class="country-column-4"><span v-if="product.marketplaces.fr.goodProduct">Y</span></td>
                                    <td class="country-column-1"><span v-if="product.marketplaces.it.goodProduct">Y</span></td>
                                    <td class="country-column-2"><span v-if="product.marketplaces.nl.goodProduct">Y</span></td>
                                    <td class="country-column-3"><span v-if="product.marketplaces.pl.goodProduct">Y</span></td>
                                    <td class="country-column-4"><span v-if="product.marketplaces.se.goodProduct">Y</span></td>-->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'SupplierProducts',
    components: {

    },
    data() {
        return {
            suppliers   : [],
            supplier    : false,
            products    : [],
            gotProducts : false
        }
    },
    mounted() {
        this.getSuppliers()
    },
    methods: {
        async getSuppliers() {
            let response   = await this.apiCall('/supplierProducts/getSuppliers')
            this.suppliers = response && response.suppliers ? response.suppliers : []
        },
        clearSupplier() {
            this.supplier = false
            this.products = []
        },
        setSupplier(supplier) {
            this.gotProducts = false
            this.supplier = supplier
            this.getSupplierProducts()
        },
        async getSupplierProducts() {
            let response     = await this.apiCall('/supplierProducts/getProducts/' + this.supplier.supplierId)
            this.products    = response.products
            this.gotProducts = true
        }
    }
}
</script>

<style>

.country-column-1 {
    background-color: #f4f5fb;
}
.country-column-2 {
    background-color: #e8eaf6;
}
.country-column-3 {
    background-color: #dadef1;
}
.country-column-4 {
    background-color: #c8cdea;
}
.country-column-5 {
    background-color: #e8eaf6;
}
.country-column-6 {
    background-color: #dadef1;
}
.country-column-7 {
    background-color: #e8eaf6;
}
.country-column-8 {
    background-color: #c8cdea;
}

</style>